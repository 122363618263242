
/* eslint-disable */
import { defineComponent, ref, reactive, computed, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { MenuComponent } from "@/assets/ts/components";

interface schoolDB {
  name?: string;
  adress: string;
  createdAt: Date;
  dbName: string;
  headMaster: string;
  legalName: string;
  logo: string;
  phone: number;
  type: string;
  updatedAt: Date;
  _id: string;
  base_url?: string;
  ArabicName: string;
}
export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const options = ref<schoolDB[]>([]);
    const selectedDB = ref<schoolDB | undefined>(undefined);
    const selectedDBName = ref<string>("default");
    const i18n = useI18n();
    const { t } = i18n;

    const submitButton = ref<HTMLElement | null>(null);
    const selectedSchool = ref();
    ApiService.setHeaderBuilding("Root");
    ApiService.post("/buildings/filter", {
      query: { active: true, "access.edParent.active": true },
    })
      .then(({ data }) => {
        data.forEach((option: schoolDB) => {
          let urlSuffix = option.base_url
            ? option.base_url + "/"
            : process.env.VUE_APP_API_URL + "/";
          if (option.logo)
            option.logo = urlSuffix + option.logo.replaceAll("\\", "/");
          else option.logo = "media/logos/edtrust.png";
        });
        options.value = data;
      })
      .catch((e) => console.log(e));

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "ar";

    try {
      if (i18n.locale.value == "ar") moment.locale("ar-tn");
      else moment.locale(i18n.locale.value);
    } catch (error) {
      moment.locale("ar-tn");
    }

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().required(t("login.requiredEmailOrUsername")),
      password: Yup.string()
        .min(4, t("login.passwordCondition"))
        .required(t("login.requiredPassword")),
      schoolName: Yup.object().nullable().required(t("login.chooseSchool")),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        ApiService.setHeaderBuilding(selectedDB.value!.dbName);
        if (selectedDB.value?.base_url) {
          ApiService.setBaseUrl(selectedDB.value?.base_url);
          let config = {
            base_url: selectedDB.value?.base_url,
            dbName: selectedDB.value?.dbName,
            building: selectedDB.value,
            ArabicName: selectedDB.value?.ArabicName,
          };
          store.commit("setServerConfig", config);
        } else ApiService.setBaseUrl(process.env.VUE_APP_API_URL);
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            Swal.fire({
              text: t("login.success"),
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            });
            // Go to page after successfully login
            router.push({ name: "dashboard" });
          })
          .catch(() => {
            Swal.fire({
              text: t("login.incorrectInfo"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("login.tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };
    /*const selectDB = (db: schoolDB) => {
      console.log("db: ", selectedDB);
      selectedDB.value = db;
      selectedDBName.value = db.dbName;
    };*/

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: i18n.t("english"),
      },
      fr: {
        flag: "media/flags/france.svg",
        name: i18n.t("french"),
      },
      ar: {
        flag: "media/flags/tunisia.svg",
        name: i18n.t("arabic"),
      },
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
      try {
        if (i18n.locale.value == "ar") moment.locale("ar-tn");
        else moment.locale(i18n.locale.value);
      } catch (error) {
        moment.locale("en");
      }
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      // selectDB,
      selectedDB,
      selectedDBName,
      onSubmitLogin,
      login,
      submitButton,
      options,
      t,
      currentLangugeLocale,
      currentLanguage,
      setLang,
      termsLink: process.env.VUE_APP_TERMS_LINK,
      acceptedTerms: ref(false),
    };
  },
});
